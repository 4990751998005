import React from 'react'
import Layout from '../components/layout2'
import Container from '../components/container'
import Box from '../components/box'
import Case from '../components/case'
import Vbox from '../components/vbox'
import Img from '../components/img'

class RootIndex extends React.Component {
  render() { 
    return <Layout>
      <div style={{position: "relative"}}>
        <svg className='shape1' width="810" height="903" viewBox="0 0 810 903" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 791.592C71.0019 748.536 161.579 753.776 227.241 805.377L247.515 821.309C267.589 837.083 290.497 848.869 315.002 856.029L447.176 894.647C529.687 918.754 618.373 887.442 667.456 816.873L715.28 748.115C729.725 727.347 740.098 704.026 745.851 679.391L803.841 431.038C815.122 382.728 808.045 331.95 783.985 288.565L753.426 233.459C742.978 214.619 740.217 192.47 745.72 171.642C759.713 118.684 719.779 66.8272 665.004 66.8272H638.565C629.727 66.8272 620.925 65.699 612.373 63.4698C612.373 63.4698 472.384 11.1398 378.329 2.46671C229.093 -11.2949 17.0214 66.8268 0 66.8268V791.592Z" fill="#FFF6A4"/>
        </svg>
        <svg className='shape2' width="722" height="1403" viewBox="0 0 722 1403" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M721.565 118.957C688.703 95.4894 649.185 82.3026 608.103 81.7326L547.884 80.8972C511.779 80.3963 477.111 66.6714 450.448 42.3222L445.305 37.6256C384.73 -17.6912 289.911 -9.8518 239.244 54.6623C219.923 79.2636 192.972 96.7449 162.631 104.357L145.723 108.599C52.7868 131.914 -8.73826 220.174 1.53708 315.437L13.7155 428.344C18.7312 474.845 39.8851 518.117 73.4993 550.637L171.974 645.907C231.145 703.152 249.191 790.807 217.444 866.77L198.27 912.648C188.713 935.517 183.489 959.963 182.866 984.741L178.495 1158.52C176.402 1241.72 226.058 1317.53 303.172 1348.85L399.5 1387.97C423.714 1397.81 449.62 1402.8 475.754 1402.67L721.565 1401.45V118.957Z" fill="#FFF6A4"/>
        </svg>
      </div>
      <Container><h1 style={{marginTop: "32px"}} className='h1'>Lite mer om mig</h1></Container>
      <Container narrow>
        <Img src="frida2.png" />
        <h2 className='h3' style={{marginTop: "64px"}}>Hej, det är jag som är Frida Runnakko! </h2>
        <p className='body'>Jag är en entusiastisk UX/UI-designer som drivs av en energi och positivitet som genomsyrar varje projekt jag tar mig an. Min bakgrund inom arkitektur och design har gett mig en unik förståelse för hur form och funktion samverkar, att växla mellan helhet och detalj samt att skapa med människan i fokus. Jag trivs när jag får arbeta kreativt och hitta nya sätt att lösa problem.</p>
        <p className='body'>Med en öppen och nyfiken inställning älskar jag att utforska idéer och samarbeta med andra för att skapa upplevelser som verkligen gör nytta. Jag strävar alltid efter att sätta användaren i fokus och förvandla komplexa koncept till enkla och engagerande interaktioner. Jag tror att den positiva energi jag ger och får från min omgivning stärker mitt kreativa arbete och hjälper mig att skapa designlösningar som verkligen gör skillnad.</p>
        <p className='body' style={{marginBottom: "64px"}}>När jag inte designar är jag alltid på jakt efter inspiration, vare sig det är i konst, musik eller ett event på en solig takterass med vänner och ett glas bubbel handen. </p>
        <Img src="om.png" caption="Ett event på Decerno, annordnat av Tjejer kodar sommaren 2024" />
        <div style={{height: "140px"}} />
      </Container>
    </Layout>
  }
}

export default RootIndex