import React from 'react'
import './base.css'
import Header from './header'
import Footer from './footer'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
        <>
          <Header />
            <main className='cv'>
                {children}
            </main>
          <Footer />
        </>
    )
  }
}

export default Template
