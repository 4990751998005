import React, {useState} from 'react'

import styles from './header.module.css'
import { Link } from 'gatsby'

const Header = () => {
        const [open, setOpen] = useState(false)

        return <header className={open && styles.open}>
        <div className={styles.inner}>
            <Link to="/">
                <svg className={styles.logo} width="46" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895429 0 0 0.895431 0 2V18.6037C0 18.7079 0.00797636 18.8103 0.0233521 18.9102C0.0244499 18.9174 0.0189469 18.9238 0.011727 18.9238C0.00525035 18.9238 0 18.9291 0 18.9356V44C0 45.1046 0.89543 46 2 46H5.37126C6.47583 46 7.37125 45.1046 7.37125 44V22.6037C7.37125 21.4991 8.26668 20.6037 9.37125 20.6037H14.9531V14.5439H9.21784C8.11327 14.5439 7.21784 13.6485 7.21784 12.5439V8.06003C7.21784 6.95546 8.11327 6.06003 9.21783 6.06003H16.0444C17.1489 6.06003 18.0444 6.95546 18.0444 8.06003V12.1953H18.0458V23H18.0403V43.8931C18.0403 44.9976 18.9357 45.8931 20.0403 45.8931H23.4115C24.5161 45.8931 25.4115 44.9976 25.4115 43.8931V22.6037C25.4115 21.4991 26.307 20.6037 27.4115 20.6037H31.3927C32.3233 20.6037 33.1309 21.2455 33.3411 22.152L38.5129 44.4618C38.7284 45.3915 39.5707 46.0394 40.5246 46.0091L43.9454 45.9007C45.2109 45.8606 46.1212 44.6695 45.8275 43.4378L40.0577 19.2397C39.8854 18.5169 40.1403 17.7687 40.6393 17.2182C42.2965 15.3899 43.3061 12.9638 43.3061 10.3018C43.3061 4.61229 38.6938 0 33.0043 0H2ZM27.2618 6.06003H31.8464C34.1891 6.06003 36.0883 7.95921 36.0883 10.302C36.0883 12.6447 34.1891 14.5439 31.8464 14.5439H27.2618C26.1572 14.5439 25.2618 13.6485 25.2618 12.5439V8.06003C25.2618 6.95546 26.1572 6.06003 27.2618 6.06003Z" fill="black"/>
                </svg>
            </Link>

            <div className={styles.button} onClick={() => setOpen(!open)} />
            <nav className={styles.nav} onClick={() => setOpen(!open)}>
                <Link to="/">Projekt</Link> 
                <Link to="/om">Om mig</Link> 
                <Link to="/kontakt">Kontakt</Link>
                <a target='_blank' href="https://drive.google.com/file/d/1i6-dLhEhFFU3mTUzw6CDe9X7oipG6d-y/view?usp=sharing">CV</a> 
                <Link to="/art">Min konst</Link>
            </nav>
        </div>
  </header>
}

export default Header