import React from 'react'
import styles from './case.module.css'
import Button from './button'
import Box from './box'

export default ({ children, title, body, link, image }) => (
  <div className={styles.case}>
    <Box>
        <div className='m2'>
          <h3 className='h3'>{title}</h3>
          <p className='body'>{body}</p>
          <p><Button to={link}>Utforska hela projektet {`->`}</Button></p>
        </div>      
        <div className={styles.img} style={{
          backgroundImage: `url(${image})`,
        }} />
    </Box> 
  </div>
)
